
import { hasEditMode } from 'utils/urls';
import { useMessaging } from 'bloc/useMessaging';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';
import type { NotificationContentType } from 'bloc/useMessaging';

const NotificationsContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 10000;
`;

const NotificationContent = styled.div(({
  theme,
}) => `
  background-color: ${theme.palette.background.lightInnerPaper};
  color: ${theme.palette.text.lightgrey};
  font-size: 1rem;
  padding: ${theme.spacing(3, 0)};
  z-index: 10000;
  cursor: pointer;
  border-bottom: 1px solid ${theme.palette.background.paper60};
  @media (max-width: 600px) {
    padding: ${theme.spacing(1, 0)};
  }
`);

interface NotificationProps {
  notification: NotificationContentType;
  onClose: (id: string) => void;
}
const Notification = ({
  notification,
  onClose,
}: NotificationProps) => {
  const {
    query,
    push,
  } = useRouter();

  const {
    t,
  } = useTranslation();

  const handleNavigation = useCallback(
    () => {
      let {
        id,
        path,
      } = notification;
      onClose(id);

      if (hasEditMode(query)) {
        path += '?facilitate';
      }
      push(path, path, {
        shallow: true,
      });
    }, [notification, onClose, push, query,]
  );

  const handleCloseClick = useCallback(
    () => {
      const {
        id,
      } = notification;
      onClose(id);
    }, [notification, onClose,]
  );

  return <NotificationContent
    onClick={handleNavigation}
  >
    <CustomGrid container>
      {/* notification icon */}
      <Grid item
        style={{
          textAlign: 'center',
        }}
        xs={2}
        md={2}>
        <NotificationsActiveIcon />
      </Grid>

      {/* main content */}
      <Grid
        rowSpacing={2}
        item
        xs={8}
        md={8} >
        <Grid
          display='flex'
          flexDirection='column'
          item
          xs={12}>
          <Title>{notification?.title}</Title>
          <Project>{notification?.body}</Project>
        </Grid>
        <Grid
          item
          xs={12}>
          <Message> {t('Join the discussion')}!
          </Message>
        </Grid>
      </Grid>
      {/* close icon */}
      <Grid
        style={{
          textAlign: 'center',
        }}
        item
        xs={2}>
        <IconButton
          component="span"
          style={{
            color: theme.palette.text.lightgrey,
          }}
          aria-label={t('close-in-app-notification')}
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </CustomGrid>
  </NotificationContent>;
};

const CustomGrid = styled(Grid)`
  justify-content: center;
  align-items: center;
`;

const Title = styled.div(({
  theme,
}) => `
  width: max-content;
  max-width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  font-size: 1.3rem;
  color: ${theme.palette.text.lightgrey};
  margin-bottom: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
  @media(max-width: 600px) {
    font-size: 1rem;
  }
`);

const Project = styled(Title)(({
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  theme,
}) => `
  font-size: 1.1rem;
  @media(max-width: 600px) {
    font-size: 0.8rem;
  }
`);

const Message = styled.div(({
  theme,
}) => `
  color: ${theme.palette.text.lightgrey};
  font-size: 0.9rem;
  line-height: 1.5rem;
  @media(max-width: 600px) {
      font-size: 0.85rem;
  }
`);

const NotificationComponent = () => {
  const {
    content,
    closeNotification,
  } = useMessaging();

  if (!content || !content.length) {
    return null;
  }

  return <NotificationsContainer >
    {content.map((notification) => {
      return <Notification
        notification={notification}
        key={notification?.id}
        onClose={closeNotification}
      />;
    })}
  </NotificationsContainer>;
};

export default NotificationComponent;
