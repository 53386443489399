
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface NotificationContentType {
  title: string;
  body: string;
  path: string;
  id: string;
}

interface NotificationSettings {
  [key: string]: boolean;
}

export const useMessaging = () => {
  const [content, setContent,] = useState<NotificationContentType[]>([]);
  const showNotification = useRef<NotificationSettings>({});

  const closeNotification = useCallback((id: string) => {
    delete showNotification.current[id];
    setContent((content) => {
      content.splice(content.findIndex((e) => e.id === id), 1);
      return [...content,];
    });
  }, []);

  useEffect(() => {
    if (content && content.length) {
      content.forEach((el) => {
        showNotification.current[el.id] = true;
      });
    }
  }, [content,]);

  /**
   * A listener to firebase messaging
   */
  useEffect(() => {
    let unsubscribeMessaging: () => void;

    try {
      (async () => {
        const supported = await isSupported();
        if (supported) {
          const messaging = getMessaging();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          unsubscribeMessaging = onMessage(messaging, (message: any) => {
            setContent((currentArray) => [...currentArray, {
              title: message.data.title,
              body: message.data.body,
              path: message.data.path,
              id: message.data.id,
            },]);
          });
        }
      })();
    } catch (error) {
      console.error('An error happened during push notification subscribe: ', error);
    }
    return () => unsubscribeMessaging && unsubscribeMessaging();
  }, []);

  return {
    content,
    showNotification,
    closeNotification,
  };
};
